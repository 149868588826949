import React from "react";
import PropTypes from "prop-types";

import Copyright from "components/Copyright";
import Visual from "components/Visual";

import "./_footer.scss";

const Footer = ({ isPrivacyPage }) => {
  return (
    <div className="footer__container" id="kontaktai">
      {!isPrivacyPage && <Visual visualType="footer" />}
      <footer className="footer">
        <div className="footer__content">
          <div className="footer__wrapper">
            <div className="footer__heading-wrapper">
              <h2 className="footer__heading">Kontaktai</h2>
            </div>
            <p className="footer__intro">
              Advokatės darbo vieta yra nuotolinė. Daugiau informacijos{" "}
              <a
                className="footer__link"
                href="http://www.advokatura.lt/lt/advokatai/praktikuojanciu-advokatu-sarasas/one/aiste-samuilyte-mamontove/5066"
                target="_blank"
                rel="noopener noreferrer"
              >
                advokatūros puslapyje.
              </a>
            </p>
          </div>
          <div className="footer__contacts">
            <a
              className="footer__link footer__link--big"
              href="tel:+37069884902"
            >
              +370 698 84902
            </a>
            <a
              className="footer__link footer__link--big"
              href="mailto:aiste.samuilyte@solaw.lt"
            >
              aiste.samuilyte@solaw.lt
            </a>
          </div>
        </div>
        <hr />
        <Copyright />
      </footer>
    </div>
  );
};

Footer.propTypes = {
  isPrivacyPage: PropTypes.bool,
};

export default Footer;
