import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import "./_visual.scss";

const Visual = ({ visualType }) => {
  const data = useStaticQuery(graphql`
    query Visual {
      bg: file(relativePath: { eq: "visual.png" }) {
        childImageSharp {
          fixed(width: 350, height: 345) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const imageData = data.bg.childImageSharp.fixed;
  const visualClass = `visual visual--${visualType}`;

  return (
    <div className={visualClass}>
      <Img fixed={imageData} alt="" />
    </div>
  );
};

export default Visual;
