import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

import "./_logo.scss";

const Logo = ({ logo, logoAlt }) => {
  return (
    <Link to="/">
      <div className="logo">
        <Img fluid={logo} alt={logoAlt} />
      </div>
    </Link>
  );
};

export default Logo;
