import React, { forwardRef } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import "./_navigation.scss";

const Navigation = forwardRef(({ toggleNav, items, isMenuOpen }, ref) => {
  const openClass = isMenuOpen ? "open" : "";

  return (
    <nav className={`navigation ${openClass}`} ref={ref}>
      <ul className={`navigation__list`}>
        {items.map(item => (
          <li
            key={item.id}
            ref={item.ref}
            onClick={() => toggleNav()}
            className="navigation__item"
          >
            <AnchorLink
              to={`/${item.slug}`}
              className="navigation__link"
              stripHash
            >
              {item.name}
            </AnchorLink>
          </li>
        ))}
      </ul>
    </nav>
  );
});

export default Navigation;
