import React from "react";
import PropTypes from "prop-types";

import Visual from "components/Visual";

import "./_main.scss";

const Main = ({ children, isPrivacyPage }) => {
  return (
    <main className="main">
      {!isPrivacyPage && <Visual visualType="about" />}
      <div className="main__content">{children}</div>
    </main>
  );
};

Main.propTypes = {
  isPrivacyPage: PropTypes.bool,
};

export default Main;
