const navigation = [
  {
    id: "5258d430-d729-44b1-96ad-484c005d",
    name: "Apie",
    slug: "#apie",
  },
  {
    id: "94596dd2-3e95-4587-bdfd-4f48af31b06d",
    name: "Praktikos sritys",
    slug: "#praktikos-sritys",
  },
  {
    id: "2fa83d93-e460-4298-a07d-70d0b3642397",
    name: "Kontaktai",
    slug: "#kontaktai",
  },
];

export default navigation;
