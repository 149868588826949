import React from "react";
import { Link } from "gatsby";

import "./_copyright.scss";

const Copyright = () => {
  return (
    <div className="copyright">
      <div className="copyright__wrapper">
        Visos teisės saugomos.{" "}
        <Link className="copyright__link" to="/privatumo-politika">
          Privatumo politika
        </Link>
      </div>
      <div className="copyright__wrapper">
        Svetainės autorius:{" "}
        <a
          className="copyright__link"
          href="https://tamulaitis.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          R. Tamulaitis
        </a>
      </div>
    </div>
  );
};

export default Copyright;
